/* hide page scrollbar */
html { scrollbar-width: none; } /* Firefox */
body { -ms-overflow-style: none; } /* IE and Edge */
body::-webkit-scrollbar, body::-webkit-scrollbar-button { display: none; } /* Chrome */
/* end hide page scrollbar */
.about-body-header {

        width: 50%;
        position: fixed;
        padding: 10px 0px;
        text-align: center;
        margin-left: 25.2%;
        z-index: 1000;
        display: flex;
        justify-content: center; /* Center align the items horizontally */
        align-items: center; /* Center align the items vertically */
        gap: 20px;
        top: 0;
        left: 0;
        
        background-color: rgba(10, 8, 21, 0.3); /* Black background with 50% transparency */
        border-radius: 5px; /* Optional: Add rounded corners */
}

/* about_us.css */
.privacy-full-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(145deg, #1e2a3a, #0a1e2f, #1d3b47, #0a1e2f, #324a5f, #0a1e2f);

  color: aliceblue;
  min-height: 100vh;
  font-family: "Poppins", Arial, sans-serif; /* Default for the page */
  
}
.terms-full-page { 

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: linear-gradient(145deg, #23252A, #1C1F24, #2A2E35, #161A1F, #2D3239, #1C2026);
  


        color: aliceblue;
        min-height: 100vh;
        font-family: "Poppins", Arial, sans-serif; /* Default for the page */
        
      }
      .notfound-full-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: linear-gradient(145deg, #1a070f, #2d0b1a, #3f0d22, #35091f, #2b071a, #320a1e);



      
        color: aliceblue;
        min-height: 100vh;
        font-family: "Poppins", Arial, sans-serif; /* Default for the page */
        
      }
      .notfound-full-page h1 { 
        font-size: 3.5rem;
        font-family: "Nunito";
      }
.about-full-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: linear-gradient(145deg, #2a1a44, #1b0f33, #3c1b5b, #180f2a, #2f1c4d, #180f2a);



        color: aliceblue;
        min-height: 100vh;
        font-family: "Poppins", Arial, sans-serif; /* Default for the page */
        
      }
      
   
      .about-home-button,
      .about-privacy-policy {
        background-color: #393950;
        color: #c7c0f5;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        font-family: "Poppins", Arial, sans-serif;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
      }
      
      .about-home-button:hover,
      .about-privacy-policy:hover {
        background-color: #c7c0f5;
        color: #181818;
      }
      
      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 2.5rem;
        margin: 80px 0 20px; /* Adjust for fixed header */
        text-align: center;
      }
      
      .about-content {
        max-width: 800px;
        background: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
        text-align: justify;
      }
      
      .about-content p {
        font-family: "Poppins", Arial, sans-serif;
        line-height: 1.8;
        margin-bottom: 15px;
        font-size: 16px;
      }
      
