@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


h1, h3 {
  font-family: 'Poppins', Arial;
}
h2 {
  font-family: "Poppins", Arial;
}

.benefits-grid h2 {
  font-family: "Poppins";
  
}
.home-body1 h1, h2 { 
  font-family: 'Nunito', Arial;
}

.benefits-grid p {
  color: aliceblue
}
body {
    margin: 0;
    color: #c7c0f5;
    background: linear-gradient(145deg, #322b3a,  #0e0e14, #393950, #0e0e14, #393950, #0e0e14);
    position: relative;
    font-family: "Poppins", sans-serif;
    
    
}

/* hide page scrollbar */
html { scrollbar-width: none; } /* Firefox */
body { -ms-overflow-style: none; } /* IE and Edge */
body::-webkit-scrollbar, body::-webkit-scrollbar-button { display: none; } /* Chrome */
/* end hide page scrollbar */
body::before {
    content: '';
    position: absolute; /* Use fixed position for the background */
    top: 0.1%;
    left: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;


   

}

  .home-fullpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: auto;
  
  }
  
  /* Logo Styling */
  .logo_header {
    max-width: 150px;
    margin: 15px auto;
    margin-left: 43.8%;
  }
  
  .logo_header-top {
    max-width: 110px;
    margin: 15px auto;
    margin-left: -89%;
    margin-top: 0%;
    margin-bottom: -4%;
  }
  
 .home-body1 .contact-button { 
  margin-left: 38%;
 }
  .home-body1 p { 
    width: 30%;
    text-shadow: 
    2px 2px 0 #000, /* Bottom-right shadow */
    -2px 2px 0 #000, /* Bottom-left shadow */
    2px -2px 0 #000, /* Top-right shadow */
    -2px -2px 0 #2f293e; /* Top-left shadow */

  
    



  }
  
  
  /* Header Section */
  .home-header-total {
    width: 50%;
    position: fixed;
    padding: 10px 0px;
    text-align: center;
    margin-left: 25.2%;
    z-index: 1000;
    display: flex;
    justify-content: center; /* Center align the items horizontally */
    align-items: center; /* Center align the items vertically */
    
    top: 0;
    left: 0;
    
    
    background-color: rgba(10, 8, 21, 0.3); /* Black background with 50% transparency */
    border-radius: 5px; /* Optional: Add rounded corners */
    
   
  }

 
  .home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
   .privacy-policy, .contact-button {
    color: #c7c0f5;
    text-decoration: none;
    font-size: 1rem;
    margin: 0 10px;
    gap: 20px;
    
  }

  .privacy-policy, .about-button {
    background-color: #393950;
    color: #c7c0f5;
    border: none;
    padding: 5px 20px;
    border-radius: 5px;
    font-family: "Nunito", Arial, sans-serif;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
  }
  .privacy-policy:hover, .about-button:hover {
    background-color: #251427;
    color: aliceblue;

  }
 
 
  .contact-button {
  
    background-color: #a89be1;
    color: black;
    padding: 5px 20px;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-family: "Nunito", Arial;
  
  }
  
  .contact-button:hover {
    background-color: rgba(6, 4, 18, 0.8);
    color: #9789f3;
 
  }
  video::-webkit-media-controls {
    display: none !important;
  }
  
  video::-moz-media-controls {
    display: none !important;
  }
  
  video::-ms-media-controls {
    display: none !important;
  }
  
  .video-placeholder video {
    display: block;
    border-radius: 15px; /* Ensures video also gets rounded corners */
    object-fit: cover; /* Makes sure the video fits without distortion */
}
  .video-placeholder {
    margin-top: -2%;
    display: inline-block; /* Ensures the glow fits tightly around the video */
    border-radius: 15px; /* Optional: rounded corners for the glow */
    box-shadow: 0 0 0 0.5px rgba(152, 141, 191, 0.6); /* Sleek margin line */

    overflow: hidden; /* Ensures no content overflows outside the rounded corners */
}

  .video-placeholder:hover {
    box-shadow: 0 0 5px rgba(199, 192, 245, 0.8), 
                0 0 5px rgba(199, 192, 245, 0.6), 
                0 0 5px rgba(199, 192, 245, 0.4);
  }
  /* First Section */
  .home-body1 {
    margin-top: 7%;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 8%;
    
  }

  
  
  .home-body1 h1, .home-body1 h2 {
    font-size: 2.91rem;
    margin: 10px 0;
    margin-left:16%;
    width: 33%;
  

    background: linear-gradient(to right, aliceblue, #a498f0); /* Define gradient colors */
    
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text color transparent */
  }
  
  .home-body1 p {
    font-size: 1.3rem;
    margin: 20px 0;
    margin-left: 54%;
    margin-top: -14%;
    
  }
  
  /* Testimonial Section */
  .home-reviews {
    padding: 40px;
    margin: 5%;
    background-color: rgba(0, 0, 0, 0.5);
    color: aliceblue;
    width: 80%;
  
  }
  
  .home-reviews h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .home-reviews h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Second Section */
  .home-body2 {
    padding: 50px 20px;
  }
  
  .home-body2 h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .home-body2 p {
    font-size: 1.2rem;
  }
  
  /* Benefits Section */
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    margin: 40px 20px;
    padding-right: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 10%;
    
    background: 
        repeating-linear-gradient(
            0deg, 
            rgba(255, 255, 255, 0.1), 
            rgba(255, 255, 255, 0.1) 1.25px, 
            transparent 1px, 
            transparent 20px
        ),
        repeating-linear-gradient(
            90deg, 
            rgba(118, 88, 128, 0.1), 
            rgba(255, 255, 255, 0.1) 1.25px, 
            transparent 1px, 
            transparent 20px
        ),
        linear-gradient(145deg, rgba(14, 14, 20, 0.8), rgba(57, 57, 80, 0.8));
    background-size: cover;
    background-blend-mode: overlay;

    backdrop-filter: blur(20px); /* Adjust the blur intensity */
    -webkit-backdrop-filter: blur(20px); /* For Safari compatibility */
    opacity: 1; /* Optional: Makes the background slightly transparent */

    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4), 0 4px 6px rgba(0, 0, 0, 0.3); /* Shadow for the grid container */
  max-width: 1500px; /* Optional: Set a max width for better layout */
  opacity: 1; /* Start fully visible */
  
  }
  
  .benefit-box {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    opacity: 1; /* Initially visible */
    transition: opacity 0.3s ease; /* Smooth fade effect */
}
  .benefit-box h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .benefit-box p {
    font-size: 1rem;
  }
  
  /* Call-to-Action Section */
  .home-cta {
    padding: 50px 20px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    max-width: 1200px;
    margin: 5% auto;
  }
  
  .home-cta form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .home-cta label {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .home-cta input, .home-cta textarea {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #c7c0f5;
    background-color: #19141c;
    color: #c7c0f5;
  }
  
  .home-cta input[type="checkbox"] {
    width: auto;
    margin-right:10px;
  }
  
  .home-cta button {
    background-color: #c7c0f5;
    color: #19141c;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .home-cta button:hover {
    background-color: #a89be1;
  }
  
  /* Footer Section */


    
  .home-footer {
    display: flex; /* Enables Flexbox */
    width: 35%;
    flex-direction: column; /* Stacks items vertically */
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    padding: 20px; /* Adds spacing inside the footer */
    background-color:none; /* Black background with 50% transparency */
    color: #c7c0f5; /* Text color */
    text-align: center; /* Centers text */
  }
  
  .home-footer p {
    margin: 10px 0; /* Adds spacing between paragraphs and other elements */
  }
  
  .logo_header {
    width: 100px; /* Adjust the size of the logo */
    margin: 10px 0px; /* Adds spacing around the logo */
  }
  
  .privacy-footer,
  .terms-footer {
    margin: 5px 10px; /* Adds spacing between links */
    text-decoration: none; /* Removes underline from links */
    color: #c7c0f5; /* Link color */
  }
  
  .privacy-footer:hover,
  .terms-footer:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  /* Alert Container */
.alert-container {
  position: fixed; /* Makes it stay on top */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  z-index: 9999; /* Ensures it's above all other elements */
  opacity: 0; /* Hidden by default */
  visibility: hidden; /* Prevents interaction when hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show Alert */
.alert-container.show {
  opacity: 1;
  visibility: visible;
}

/* Alert Box */
.alert-box {
  background-color: #ffffff; /* White background */
  color: #333333; /* Text color */
  padding: 20px 30px; /* Adds padding inside the box */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Adds a shadow */
  text-align: center; /* Centers text inside the box */
  max-width: 400px; /* Sets a maximum width */
  width: 80%; /* Responsive width */
}

/* Alert Message */
#alert-message {
  font-size: 16px; /* Adjusts font size */
  margin-bottom: 15px; /* Adds space below the message */
  display: block; /* Ensures the message is on its own line */
}

/* Close Button */
.close-btn {
  background-color: #007bff; /* Button color */
  color: #ffffff; /* Text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* media queries */



/* media queries */

@media (max-width: 768px) {

  
  /* Header Background */
  .home-header {
    
    padding: 10px 0; /* Reduced padding for a smaller header */
    background-color: transparent; /* Keep background transparent */
    height: auto; /* Ensure the header adjusts dynamically based on content */
  }

  /* Logo */
  .logo_header {
    max-width: 80px; /* Reduced logo size further */
    margin: 0; /* Reset margin */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Center the logo */
  }
.logo_header-top { 
  max-width: 70px;
  padding: 10px;
  margin-right: -10%;
  margin-top: 3%;
}
  /* Header Text (Heading & Subtext) */
  .home-header-total {
    display: flex;
    flex-direction: column; /* Stack items for better compactness */
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px auto; /* Center it horizontally */
    width: 80%; /* Keep it responsive */
    max-width: 300px; /* Prevent it from getting too wide */
    padding: 0.5px; /* Add slight padding for spacing */
    margin-left: 20%;
  }

  .home-header-total h1 {
    font-size: 1.5rem; /* Reduce font size */
    margin-bottom: 5px; /* Add spacing */
  }

  .home-header-total p {
    font-size: 0.9rem; /* Smaller font for compactness */
  }
  .video-placeholder {
    width: 100%; /* Ensure the container takes full width */
    height: 0;
    margin-bottom: 10%;
    padding-bottom: 56.25%; /* 16:9 Aspect ratio (can be adjusted based on your needs) */
    position: relative;
    overflow: hidden; /* Hide anything outside the container */
}

.video-placeholder video {
    position: absolute; /* Position the video inside the container */
    top: 0;
    left: 0;
    width: 100%; /* Stretch the video to fill the container */
    height: 100%; /* Stretch the video to fill the container */
    object-fit: cover; /* Make sure it fills the container properly without distorting */
}

  /* Grid Boxes */
  .benefits-grid {
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 10px; /* Adjust gap between grid boxes */
    justify-content: center;
  }

  .home-services h1 { 
    font-size: 1.3rem;
    
  }
  .benefit-box {
    font-size: 1rem; /* Adjust font size */
    padding: 15px; /* Adjust padding for smaller boxes */
    box-sizing: border-box;
    text-align: center; /* Center text inside boxes */
  }

  .benefit-box img {
    max-width: 50px; /* Shrink image size */
    height: auto;
    margin-bottom: 10px;
  }

  /* Adjusting Other Sections */
  .home-body1 {
    margin-top: 15%;
    margin-bottom: 10%;
    text-align: center; /* Align text in the home body */
    
  }

  .home-body1 h1, .home-body1 h2 {
    font-size: 1.8rem; /* Adjust font size for headings */
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .home-body1 p {
    font-size: 1rem;
    width: 100%;
    margin-left: 3%;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 10%;
    margin-top: 30px;
  }


  .home-body1 .contact-button { 
    margin-left: 3%;
  }
.home-cta h1 {
  font-size: 1.5rem;
}
  /* Form adjustments */
  .home-cta input[type="submit"] {
    font-size: 1rem;
    padding: 12px 25px;
  }

  .home-cta input, .home-cta textarea {
    width: 90%;
    padding: 12px;
    margin-bottom: 15px;
  }

  .home-footer {
    font-size: 1rem;
    text-align: center;
    padding: 15px;
  }

  .home-reviews { 
    height: 200px;
  }
  .home-reviews h1 { 
    font-size: 1.5rem;
  }
  .home-reviews h3 {
    font-size: 1.3rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {

  /* Header Background */
  .home-header {
    padding: 15px 0;
    background-color: transparent;
    height: auto;
  }

  /* Logo */
  .logo_header {
    max-width: 100px;
    margin: 0 auto;
    display: block;
  }

  .logo_header-top { 
    max-width: 90px;
    padding: 10px;
    margin-right: -5%;
    margin-top: 2%;
  }

  /* Header Text */
  .home-header-total {
    display: flex;
    flex-direction: column; /* Stack elements */
    justify-content: center;
    align-items: center; /* Ensures horizontal centering */
    text-align: center;
    margin: 0 auto; /* Automatically centers in the parent container */
    width: 100%; /* Ensures it spans the container */
    max-width: 500px; /* Adjust as needed to prevent it from stretching */
    margin-left: 25%;
}



  .home-header-total h1 {
    font-size: 2rem;
    margin-right: 10px;
  }

  .home-header-total p {
    font-size: 1.1rem;
  }

  /* Video Section */
  .video-placeholder {
    width: 100%;
    height: 0;
    margin-bottom: 5%;
    padding-bottom: 56.25%; /* 16:9 Aspect ratio */
    position: relative;
    overflow: hidden;
  }

  .video-placeholder video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Grid Boxes */
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    justify-content: center;
  }

  .home-services h1 { 
    font-size: 1.6rem;
  }

  .benefit-box {
    font-size: 1.1rem;
    padding: 20px;
    text-align: center;
  }

  .benefit-box img {
    max-width: 70px;
    height: auto;
    margin-bottom: 10px;
  }

  /* Other Sections */
  .home-body1 {
    margin-top: 10%;
    margin-bottom: 8%;
    text-align: center;
  }

  .home-body1 h1, .home-body1 h2 {
    font-size: 2rem;
    width: 80%;
    margin: 0 auto;
  }

  .home-body1 p {
    font-size: 1.1rem;
    width: 90%;
    margin: 20px auto;
    text-align: center;
  }

  .home-body1 .contact-button { 
    margin: 0 auto;
    margin-right: 4px;
  }

  /* Form Adjustments */
  .home-cta {
    padding: 50px 40px;
    max-width: 800px;
    margin: 5% auto;
  }

  .home-cta h1 {
    font-size: 1.8rem;
  }

  .home-cta form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-cta input, .home-cta textarea {
    width: 80%;
    max-width: 500px;
    padding: 12px;
    margin-bottom: 15px;
  }

  .home-cta input[type="submit"] {
    font-size: 1.1rem;
    padding: 12px 30px;
  }

  .home-footer {
    font-size: 1.1rem;
    text-align: center;
    padding: 20px;
  }

  /* Reviews Section */
  .home-reviews { 
    height: auto;
  }

  .home-reviews h1 { 
    font-size: 1.8rem;
  }

  .home-reviews h3 {
    font-size: 1.5rem;
  }
}


/* Responsive Fixes */
@media (max-width: 1024px) {
  .home-cta {
    width: 95%;
    padding: 40px 15px;
  }
}

@media (max-width: 768px) {
  .home-cta {
    width: 100%;
    padding: 30px 10px;
  }

  .home-cta input, .home-cta textarea {
    font-size: 0.9rem;
  }

  .home-cta button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}